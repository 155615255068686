import admin from "@/services/admin.service";
import { toast } from "@/services/toaster";
import user from "@/services/user.service";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
declare let swal: any;
function guardMyroute(to: any, from: any, next: any) {
  let isAuthenticated = false;
  if (localStorage.getItem("escsid")) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) {
    next();
  } else {
    next("/");
  }
}

async function guardMyadmin(to: any, from: any, next: any) {
  let isAuthenticated = false;
  const res = await admin.getRole();
  if (localStorage.getItem("escsid")) isAuthenticated = true;
  else isAuthenticated = false;

  if (isAuthenticated && res.role == "admin") {
    next();
  } else {
    next("/");
  }
}

async function checkVerified(to: any, from: any, next: any) {
  const res: any = await user.getProfile();
  const bank = res.banks;
  const name = res.name;
  const status = res.status;
  if (bank.length < 1 || !name || status < 1) {
    toast.notify("info", "Verify Your account to Continue");
    return swal({
      icon: "warning",
      title: "Verification Required",
      text: "Verify your account to perform any transaction",
      button: "Verify now",
    }).then(function () {
      next("/dash/profile");
    });
  }
  next();
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/layouts/auth/Login.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/layouts/auth/Signup.vue"
      ),
  },
  {
    path: "/vendor/:id",
    name: "vendor",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/layouts/shop.vue"),
  },
  {
    path: "/vendors",
    name: "vendors",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/layouts/Vendors.vue"),
  },
  {
    path: "/forgot_pass",
    name: "forgot",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/layouts/auth/ForgotPass.vue"
      ),
  },

  {
    path: "/changepass",
    name: "change",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/layouts/auth/Changepass.vue"
      ),
  },

  {
    path: "/calculator",
    name: "calc",
    component: () => import("../components/includes/Form.vue"),
  },

  {
    path: "/timeline",
    name: "time",
    component: () => import("../components/includes/Timeline.vue"),
  },
  {
    path: "/join-transaction/:id",
    name: "join-transaction",
    component: () => import("../views/layouts/dashboard/Join-transaction.vue"),
  },

  {
    path: "/dash",
    name: "layout",
    beforeEnter: guardMyroute,
    component: () => import("../views/layouts/dashboard/Layout.vue"),
    children: [
      {
        path: "",
        name: "content",
        component: () => import("../views/layouts/dashboard/Transhistory.vue"),
      },

      {
        path: "start",
        name: "start",
        beforeEnter: [checkVerified],
        component: () => import("../views/layouts/dashboard/Home.vue"),
      },

      {
        path: "join",
        name: "join",
        component: () => import("../views/layouts/dashboard/Join.vue"),
      },
      {
        path: "profile",
        name: "profile",
        component: () => import("../views/layouts/dashboard/Profile.vue"),
      },

      {
        path: "transaction/:id",
        name: "transaction",
        beforeEnter: [checkVerified],
        component: () => import("../views/layouts/dashboard/Transaction.vue"),
      },
      {
        path: "verify/account",
        name: "verify.account",
        component: () =>
          import("../views/layouts/dashboard/Verify.account.vue"),
      },
      {
        path: "vendor/create",
        name: "vendor.dash",
        component: () => import("../views/layouts/dashboard/Vendor.dash.vue"),
      },
      {
        path: "vendor/shop/:shop",
        name: "vendor.shop",
        component: () => import("../views/layouts/dashboard/Vendor.shop.vue"),
      },
      {
        path: "vendor/product/:shop/create/:id",
        name: "product.create",
        component: () => import("../views/layouts/dashboard/Vendor.create.vue"),
      },
      {
        path: "vendor/product/:shop/edit",
        name: "vendor.edit",
        component: () =>
          import("../views/layouts/dashboard/Vendor.shop.edit.vue"),
      },
      {
        path: "account/password",
        name: "password",
        component: () =>
          import("../views/layouts/dashboard/Update.password.vue"),
      },

      {
        path: "account/disbursement-options",
        name: "disbursement",
        component: () =>
          import("../views/layouts/dashboard/Method.disbursement.vue"),
      },
    ],
  },

  {
    path: "/admin-linkdbux",
    name: "adlayout",
    beforeEnter: guardMyadmin,
    component: () => import("../views/layouts/admin/Layout.vue"),
    children: [
      {
        path: "",
        name: "adcontent",
        component: () => import("../views/layouts/admin/Content.vue"),
      },
      {
        path: "/admin-linkdbux/users",
        name: "users",
        component: () => import("../views/layouts/admin/Users.vue"),
      },
      {
        path: "/admin-linkdbux/user/:id",
        name: "user",
        component: () => import("../views/layouts/admin/User.vue"),
      },
      {
        path: "/admin-linkdbux/payment",
        name: "pay",
        component: () => import("../views/layouts/admin/Pay.vue"),
      },
      {
        path: "/admin-linkdbux/history",
        name: "history",
        component: () => import("../views/layouts/admin/History.vue"),
      },
      {
        path: "/admin-linkdbux/transactions",
        name: "trans",
        component: () => import("../views/layouts/admin/Trans.vue"),
      },
      {
        path: "/admin-linkdbux/withdraw",
        name: "withdraw",
        component: () => import("../views/layouts/admin/Withdraw.vue"),
      },
      {
        path: "/admin-linkdbux/invoice/:id",
        name: "invoice",
        component: () => import("../views/layouts/admin/Invoice.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
