import axios from "axios";
import store from "../store";
import { toast } from "./toaster";
import { environment } from "../environments/environment";

import spin from "@/functions/funtions.global";

export default {
  url: environment.serverURL,

  async post(url: string, data: any) {
    spin.loadSpinner();
    let res = await axios.post(url, data).catch((e) => {
      spin.removeSpinner();
      return toast.notify("error", "maintanance mode");
    });
    if (res) {
      spin.removeSpinner();
      if (res.data.statusCode == 200) return res.data.data;
      return toast.notify("info", res.data.statusText);
    }
  },

  async get(url: string) {
    spin.loadSpin();
    let res = await axios.get(url).catch((e) => {
      return toast.notify("error", "maintanance mode");
    });
    if (res) {
      spin.removeSpin();
      if (res.data.statusCode == 200) return res.data.data;
      return toast.notify("warning", res.data.statusText);
    }
  },

  getRole() {
    return this.get(`${this.url}/admin/token/role`);
  },

  getTrans(page = 1) {
    return this.get(`${this.url}/admin/trans?page=` + page);
  },

  getPays(page = 1) {
    return this.get(`${this.url}/admin/payments?page=` + page);
  },

  getWithdraws(page = 1) {
    return this.get(`${this.url}/admin/withdraws?page=` + page);
  },

  disable(x: any) {
    return this.post(`${this.url}/auth/disable`, x);
  },

  makeAdmin(x: any) {
    return this.post(`${this.url}/admin/make_admin`, x);
  },

  getUser(id: any) {
    return this.get(`${this.url}/admin/user/` + id);
  },

  async getUsers(page = 1) {
    return await this.get(`${this.url}/admin/users?page=` + page);
  },

  getSingleTrans(id: any) {
    return this.get(`${this.url}/admin/trans/` + id);
  },
};
