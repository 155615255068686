import axios from "axios";
/* import store from "../store"; */
import { toast } from "./toaster";
import { environment } from "../environments/environment";
import spin from "@/functions/funtions.global";
export default {
  url: environment.serverURL,

  async post(url: string, data: any) {
    spin.loadSpinner();
    let res = await axios.post(url, data).catch((e) => {
      spin.removeSpinner();
      return toast.notify("error", "maintanance mode");
    });
    if (res) {
      spin.removeSpinner();
      if (res.data.statusCode == 200) return res.data.data;
      return toast.notify("info", res.data.statusText);
    }
  },

  async get(url: string) {
    spin.loadSpin();
    let res = await axios.get(url).catch((e) => {
      return toast.notify("error", "maintanance mode");
    });
    if (res) {
      spin.removeSpin();
      if (res.data.statusCode == 200) return res.data.data;
      return toast.notify("warning", res.data.statusText);
    }
  },

  async put(url: string, data: any) {
    spin.loadSpinner();
    let res = await axios.put(url, data).catch((e) => {
      spin.removeSpinner();
      return toast.notify("error", "maintanance mode");
    });
    if (res) {
      spin.removeSpinner();
      if (res.data.statusCode == 200) return res.data.data;
      return toast.notify("info", res.data.statusText);
    }
  },

  getTag() {
    return this.get(`${this.url}/auth/categories`);
  },

  updateProfile(x: any) {
    return this.put(`${this.url}/profile`, x);
  },

  updatePassword(x: any) {
    return this.put(`${this.url}/profile/password`, x);
  },

  bankActive(x: any) {
    return this.put(`${this.url}/profile/bank/active`, x);
  },

  withdrawEarn(data: any) {
    return this.post(`${this.url}/trans/transaction/withdraw`, data);
  },

  bankDelete(x: any) {
    return this.put(`${this.url}/profile/bank/delete`, x);
  },
  getProfile() {
    return this.get(`${this.url}/profile`);
  },

  resendMail() {
    return this.get(`${this.url}/profile/mail`);
  },

  getToken() {
    return localStorage.getItem("escsid");
  },
};
