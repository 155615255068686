
import NotificationsList from "./components/includes/NotificationsList.vue";
import { reactive } from "vue";

export default {
  components: {
    NotificationsList,
  },

  setup() {
    const obj = reactive({
      email: "",
      name: "",
    });
    function send() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=+2347034553577&text=" +
        obj.name +
        "%0A" +
        "-" +
        "%0A" +
        "%0A" +
        obj.email +
        "";
    }
    return {
      send,
      obj,
    };
  },
};
