
import NotificationMessages from "./NotificationMessages.vue";
import { mapState } from "vuex";

export default {
  components: {
    NotificationMessages,
  },

  computed: mapState(["notifications"]),
};
