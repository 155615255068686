import moment from "moment";
import $ from "jquery";
export default {
  toCurrency(value: any) {
    if (typeof value !== "number") {
      return value;
    }

    var formatter = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(value);
  },

  dateFormat(date: any, ty?: any) {
    if ((ty = 1)) {
      return moment(String(date)).format("LLL");
    } else {
      return moment(String(date)).fromNow();
    }
  },

  loadSpin() {
    $("[id^=loads]").addClass("dot-pulse");
  },

  removeSpin() {
    $("[id^=loads]").removeClass("dot-pulse");
  },

  loadSpinner() {
    let ele = document.getElementById("spinner");
    ele?.classList.add("spinner-border");
    let bu = document.querySelector(
      'button[type="submit"]'
    ) as HTMLButtonElement | null;
    if (bu != null) {
      // 👉️ btn has type HTMLButtonElement here
      bu.disabled = true;
    }
  },

  removeSpinner() {
    let ele = document.getElementById("spinner");
    ele?.classList.remove("spinner-border");
    let bu = document.querySelector(
      'button[type="submit"]'
    ) as HTMLButtonElement | null;
    if (bu != null) {
      // 👉️ btn has type HTMLButtonElement here
      bu.disabled = false;
    }
  },

  removeNav() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    if (isMobile) {
      if ($("#page-container").hasClass("sidebar-o-xs")) {
        $("#page-container").toggleClass(
          "sidebar-o sidebar-o-xs side-overlay-o"
        );
      }
    }
  },
};
